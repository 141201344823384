import { Container, Navbar, useTheme } from '@nextui-org/react';
import Footer, { footerHeight } from 'components/footer';
import { MoonIcon, SunIcon } from 'components/images';
import { url } from 'lib/constants';
import { useTheme as useNextTheme } from 'next-themes';
import Head from 'next/head';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { StrictMode, Suspense, useEffect, useState } from 'react';

interface IPageContainer {
  children: JSX.Element | JSX.Element[];
  css: Object;
}

const PageContainer = (props) => {
  const [, setMounted] = useState(false);
  // After mounting, we have access to the theme
  useEffect(() => setMounted(true), []);

  const { setTheme } = useNextTheme();
  const { isDark } = useTheme();

  const { children, css, ...customMeta } = props;
  const router = useRouter();
  const meta = {
    type: 'website',
    title: 'Rikhil Raithatha', // –
    description: 'Rikhil Raithatha. Musician, avid sportsperson and full stack software engineer.',
    image: {
      alt: 'Rikhil Raithatha banner',
      path: `/banner.png`,
      width: 1200,
      height: 630,
      type: 'image/png',
    },
    squareImage: {
      alt: 'Rikhil Raithatha',
      path: `/logo-square.png`,
      width: 256,
      height: 256,
      type: 'image/png',
    },
    pathPrefix: '',
    shouldCrawlAndIndex: true,
    iconBackgroundColor: '#ffffff',
    app: undefined,
    canonicalUrl: undefined,
    ...customMeta,
  };

  if (meta.pathPrefix && !meta.pathPrefix.startsWith('/')) {
    console.error(`pathPrefix: ${meta.pathPrefix} must start with "/".`);
  }

  const shouldCrawlAndIndex = meta.shouldCrawlAndIndex !== undefined ? meta.shouldCrawlAndIndex : true;
  const canonicalUrl = meta.canonicalUrl !== undefined ? meta.canonicalUrl : `${url}${router.asPath}`;

  return (
    <StrictMode>
      <Head>
        {/* https://github.com/DeveloperRSquared/website/blob/main/src/pages/projectsPage.tsx */}
        <link rel="apple-touch-icon" sizes="180x180" href={`${url}${meta.pathPrefix}/apple-touch-icon.png`} />
        {/* favicon */}
        <link rel="icon" type="image/png" sizes="32x32" href={`${url}${meta.pathPrefix}/favicon-32x32.png`} />
        <link rel="icon" type="image/png" sizes="16x16" href={`${url}${meta.pathPrefix}/favicon-16x16.png`} />
        <link rel="manifest" href={`${url}${meta.pathPrefix}/manifest.json`} />
        {/* Safari pinned tab icon */}
        <link
          rel="mask-icon"
          href={`${url}${meta.pathPrefix}/safari-pinned-tab.svg`}
          color={meta.iconBackgroundColor}
        />
        {/* favicon */}
        <link rel="shortcut icon" href={`${url}${meta.pathPrefix}/favicon.ico`} />
        {meta.app && (
          <>
            <meta name="application-name" content={meta.app.title} />
            <meta name="apple-mobile-web-app-title" content={meta.app.title} />

            <meta property="al:ios:url" content={meta.app.deepLinkHomePath} />
            <meta property="al:ios:app_store_id" content={meta.app.appStoreId} />
            <meta property="al:ios:app_name" content={meta.app.title} />

            <meta property="al:android:url" content={meta.app.deepLinkHomePath} />
            <meta property="al:android:app_name" content={meta.app.title} />
            <meta property="al:android:package" content={meta.app.googlePackage} />

            <meta property="al:web:url" content={`${url}${meta.app.path}`} />
            <meta property="al:web:should_fallback" content="false" />
          </>
        )}
        {/* Windows tile config */}
        <meta name="msapplication-TileColor" content={meta.iconBackgroundColor} />
        <meta name="msapplication-config" content={`${url}${meta.pathPrefix}/browserconfig.xml`} />

        {/* theme-color: customize the display of the User Interface */}
        <meta name="theme-color" media="(prefers-color-scheme: light)" content="#ffffff" />
        <meta name="theme-color" media="(prefers-color-scheme: dark)" content="#000000" />

        <title>{meta.title}</title>
        {shouldCrawlAndIndex ? (
          <meta name="robots" content="index, follow" />
        ) : (
          <meta name="robots" content="noindex, nofollow" />
        )}
        <link rel="canonical" key="canonical" href={canonicalUrl} />
        <meta name="description" key="description" content={meta.description} />

        {/* Open graph tags */}
        <meta property="og:url" content={`${url}${router.asPath}`} />
        <meta property="og:type" content={meta.type} />
        <meta property="og:site_name" content="Rikhil Raithatha" />
        <meta property="og:description" content={meta.description} />
        <meta property="og:title" content={meta.title} />
        <meta property="og:image" content={`${url}${meta.image.path}`} />
        <meta property="og:image:alt" content={meta.image.alt} />
        <meta property="og:image:type" content={meta.image.type} />
        <meta property="og:image:width" content={meta.image.width.toString()} />
        <meta property="og:image:height" content={meta.image.height.toString()} />

        {meta.squareImage && (
          <>
            <meta property="og:image" content={`${url}${meta.squareImage.path}`} />
            <meta property="og:image:alt" content={meta.squareImage.alt} />
            <meta property="og:image:type" content={meta.squareImage.type} />
            <meta property="og:image:width" content={meta.squareImage.width.toString()} />
            <meta property="og:image:height" content={meta.squareImage.height.toString()} />
          </>
        )}
        {/* Twitter meta tags */}
        <meta name="twitter:domain" content={url} />
        <meta name="twitter:url" content={`${url}${router.asPath}`} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@rikhilrai" />
        <meta name="twitter:title" content={meta.title} />
        <meta name="twitter:description" content={meta.description} />
        <meta name="twitter:image" content={`${url}${meta.image.path}`} />
        <meta name="twitter:image:alt" content="Rikhil Raithatha" />
      </Head>

      <div
        style={{
          height: '100vh',
          width: '100vw',
          userSelect: 'none',
          justifyContent: 'center',
        }}
      >
        <Navbar isBordered variant={'static'}>
          <Navbar.Brand>
            {/* TODO(rikhil): only render link when not on Home */}
            <Link href="/" aria-label="home">
              <Image
                alt="Rikhil Raithatha logo"
                src={isDark ? '/logo-white.png' : '/logo.png'}
                width={32}
                height={32}
              />
            </Link>
            {/* <Navbar.Content>
            <Navbar.Link isActive href="#skip">Skip to content</Navbar.Link>
          </Navbar.Content> */}
          </Navbar.Brand>
          {isDark ? (
            <SunIcon style={{ cursor: 'pointer' }} onClick={() => setTheme('light')} />
          ) : (
            <MoonIcon style={{ cursor: 'pointer' }} onClick={() => setTheme('dark')} />
          )}
        </Navbar>
        {/* This div is for the scrollable area on the page */}
        <div
          style={{
            height: `calc(100vh - var(--nextui--navbarHeight) - ${footerHeight})`,
            width: '100vw',
            overflow: 'auto',
            display: 'flex',
          }}
        >
          {/* This container sets the gutters on the page */}
          <Container sm={true} id="container" css={{ display: 'flex', justifyContent: 'center' }}>
            <main id="skip" style={css}>
              <Suspense fallback={null}>{children}</Suspense>
            </main>
          </Container>
        </div>
        <Footer />
      </div>
    </StrictMode>
  );
};

export default PageContainer;
