import { createTheme, NextUIProvider } from '@nextui-org/react';
import { Analytics } from '@vercel/analytics/react';
import ErrorBoundary from 'components/errorBoundary';
import * as gtag from 'lib/gtag';
import * as gtm from 'lib/gtm';
import { ThemeProvider as NextThemesProvider } from 'next-themes';
import type { AppProps, NextWebVitalsMetric } from 'next/app';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

const lightTheme = createTheme({
  type: 'light',
  theme: {
    // colors: {...}, // optional
  },
});

const darkTheme = createTheme({
  type: 'dark',
  theme: {
    // colors: {...}, // optional
  },
});

const App = ({ Component, pageProps }: AppProps) => {
  const router = useRouter();

  useEffect(() => {
    // https://github.com/vercel/next.js/blob/canary/examples/with-google-analytics/pages/_app.js
    const handleRouteChange = (url: string): void => {
      gtag.pageview(url);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    router.events.on('hashChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
      router.events.off('hashChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <NextThemesProvider
      defaultTheme="light"
      attribute="class"
      value={{
        light: lightTheme.className,
        dark: darkTheme.className,
      }}
    >
      <ErrorBoundary>
        <NextUIProvider>
          {gtm.registerGoogleTagManager()}
          <Component {...pageProps} />
          {process.env.NODE_ENV === 'production' && <Analytics />}
        </NextUIProvider>
      </ErrorBoundary>
    </NextThemesProvider>
  );
};

export function reportWebVitals(metric: NextWebVitalsMetric) {
  gtag.webVitals(metric);
}

export default App;
